import React, {useState} from 'react';
import {motion} from "framer-motion";
import s from "../KycPersonalStepTwo/KycPersonalStepTwo.module.css";
import CustomModal from "../../../../../../components/ui/CustomModal/CustomModal";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import * as yup from "yup";
import {Field, Formik} from "formik";
import {editKycThunk, setTokenState} from "../../../../../../store/reducers/ActionCreators";
import {Checkbox, DatePicker, Popover} from "antd";
import info from "../../../../../../assets/newImg/personalAccount/information.svg";
import MyInput from "../../../../../../components/ui/MyInput/MyInput";
import CustomDropdown from "../../../../../../components/ui/CustomDropdown/CustomDropdown";
import classnames from "classnames";
import CustomCheckbox from "../../../../../../components/ui/customCheckbox/CustomCheckbox";
import passportSpread from "../../../../../../assets/newImg/personalAccount/passport_spread.png";
import selfi from "../../../../../../assets/newImg/personalAccount/selfi.png";
import proof from "../../../../../../assets/newImg/personalAccount/proof.png";
import idCard from "../../../../../../assets/newImg/personalAccount/idCard.png"
import {ReactComponent as Back}  from "../../../../../../assets/newImg/personalAccount/back.svg";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import Icon from "@ant-design/icons";
import TransparentButton from "../../../../../../components/ui/TransparentButton/TransparentButton";
import {countries, phonecodes} from "../../../../../../Constants/StaticData";
import {setDisabled} from "../../../../../../store/reducers/UserSlice";
import {IPhoneNumber} from "../../../../../../types/interfaces";
import SelectWithSearch from "../../../../../../components/ui/SelectWithSearch/SelectWithSearch";
import dayjs from "dayjs";
import {toBase64} from "../../../../../../helpers/toBase64";
import {brandConfig} from '../../../../../../config/config';
import FileInput from '../../../../../../components/FileInput/FileInput';

const validationSchema = yup.object({
    phoneNumber: yup.string()
        .required('Required field'),
    firstName: yup.string()
        .max(90, 'max 90')
        .required('Required field'),
    lastName: yup.string()
        .required('Required field')
        .max(90, 'max 90'),
    citizenship: yup.string().required('Required field').max(90, 'max 90'),
    country: yup.string().required('Required field').max(90, 'max 90'),
    city: yup.string().required('Required field').max(50, 'max 50'),
    address: yup.string().required('Required field').max(255, 'max 255'),
    identityCardType: yup.string().required('Required field').max(50, 'max 50'),
    identityCardId: yup.string().required('Required field').max(70, 'max 70'),
    postalCode: yup.string().required('Required field').max(50, 'max 50'),
    taxNumber: yup.string().required('Required field').max(60, 'max 60'),
    otherSourcesOfWealth: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {isOtherSourcesOfWealth} = this.parent;
            if (isOtherSourcesOfWealth) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    currencies: yup.array().min(1, "At least one account is required"),
    sourcesOfWealth: yup.array().test({
        message: 'At least one source is required',
        test: function (value) {
            const {isOtherSourcesOfWealth} = this.parent;
            if (!isOtherSourcesOfWealth) return value !== undefined && value !== null && value.length > 0;
            return true
        },
    }),
})

const EditKycPersonal = (props: any) => {

    const token: string = useAppSelector(state => state.userReducer.user.userToken)
    const newKycList = useAppSelector(state => state.userReducer.user.kycList)
    const dispatch = useAppDispatch()

    // @ts-ignore
    const phoneNumber = newKycList[0].phoneNumber

    const [documents, setDocuments] = useState<any>(
        {
            passportSpread: '',
            selfie: '',
            proofOfAddress: '',
            backOfIdCard: '',
            idCard: '',
        }
    )

    const docsError = false;
    const [isPep, setIsPep] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again later.");
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const initPhoneNumbers = useAppSelector(state => state.userReducer.user.phoneList);
    const [date, setDate] = useState({
        // @ts-ignore
        from: newKycList[0].dateOfBirth?.date,
    })

    const getPhoneNumbersArr = (): string[] => {
        const phonesArr: string[] | null = [];
        initPhoneNumbers?.forEach((item: IPhoneNumber) => {
            if (item.phoneNumber != null) {
                phonesArr.push(item.phoneNumber);
            }
        });
        return phonesArr;
    }

    const idCardsTypes = [
        'Passport',
        'National ID',
        'Driver’s License',
    ]

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 55,
        width: "100%",
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dateFormat = 'YYYY-MM-DD';

    const closeAndRedirect = () => {
        setIsModalOpen(false)
        props.setCurrent(0)
    }

    const sourcesOfWealthHandlerChange = (e: any, meta: any, form: any, name: string) => {
        form.setFieldValue("otherSourcesOfWealth", '');
        form.setFieldValue("isOtherSourcesOfWealth", false);
        const {checked} = e.target;
        if (checked) {
            form.setFieldValue("sourcesOfWealth", [...meta.value.sourcesOfWealth, name]);
        } else {
            const filteredArr = meta.value.sourcesOfWealth.filter((i: string) => i !== name)
            form.setFieldValue("sourcesOfWealth", filteredArr);
        }
    }

    const getCountryCode = (countryName: string) => {
        const country = phonecodes.find((entry) => entry.na === countryName);
        return country?.co;
    };

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>

                <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                    <div className={s.flex}>
                        <div className={s.modal_title}>
                            Success!
                        </div>
                        <div className={s.modal_subtitle}>
                            Your details will be sent for review.
                        </div>
                        <div className={s.button_block}>
                            <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal type="error" isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                    <div className={s.flex}>
                        <div className={s.modal_title}>
                            Oops!
                        </div>
                        <div className={s.modal_subtitle}>
                            {errorMessage}
                        </div>
                        <div className={s.button_block}>
                            <MyBtn buttonType="error" title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                        </div>
                    </div>
                </CustomModal>

                <div className={s.page_title}>
                    KYC for Personal Accounts
                </div>

                <Formik
                    initialValues={{
                        // @ts-ignore
                        firstName: newKycList[0].firstName,
                        // @ts-ignore
                        lastName: newKycList[0].lastName,
                        // @ts-ignore
                        kycId: newKycList[0]?.id,
                        // @ts-ignore
                        country: newKycList[0].country,
                        // @ts-ignore
                        phoneNumber: phoneNumber,
                        // @ts-ignore
                        sourcesOfWealth: newKycList[0].sourcesOfWealth,
                        // @ts-ignore
                        isOtherSourcesOfWealth: newKycList[0].isOtherSourcesOfWealth,
                        // @ts-ignore
                        otherSourcesOfWealth: newKycList[0].isOtherSourcesOfWealth ? newKycList[0].sourcesOfWealth[0] : '',
                        // @ts-ignore
                        currencies: newKycList[0].currencies,
                        // @ts-ignore
                        city: newKycList[0].city,
                        // @ts-ignore
                        state: newKycList[0].state,
                        // @ts-ignore
                        identityCardType: newKycList[0].identityCardType,
                        // @ts-ignore
                        identityCardId: newKycList[0].identityCardId,
                        // @ts-ignore
                        postalCode: newKycList[0].postalCode,
                        // @ts-ignore
                        address: newKycList[0].address,
                         // @ts-ignore
                        taxNumber: newKycList[0].taxNumber,
                        // @ts-ignore
                        citizenship: newKycList[0].citizenship,
                        pep: false,
                        files: {
                            passportSpread: '',
                            selfie: '',
                            proofOfAddress: '',
                        }
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        let tempData = {
                            ...values,
                            dateOfBirth: date.from,
                            phoneNumber: values.phoneNumber,
                            citizenship: getCountryCode(values.citizenship ? values.citizenship : ''),
                            country: getCountryCode(values.country ? values.country : ''),
                            pep: isPep,
                            files: {}

                        }

                        let convertedPassportSpread = await toBase64(documents.passportSpread) as string
                        let convertedProofOfAddress = await toBase64(documents.proofOfAddress) as string
                        let convertedSelfie = await toBase64(documents.selfie) as string


                        if (convertedPassportSpread) {
                            tempData.files = {
                                ...tempData.files,
                                passportSpread: convertedPassportSpread,
                            }
                        }


                        if (convertedProofOfAddress) {
                            tempData.files = {
                                ...tempData.files,
                                proofOfAddress: convertedProofOfAddress,
                            }
                        }


                        if (convertedSelfie) {
                            tempData.files = {
                                ...tempData.files,
                                selfie: convertedSelfie,
                            }
                        }

                        if (documents.idCard !== '') {
                            // @ts-ignore
                            let convertedIdCard = await toBase64(documents.idCard) as string

                            if (convertedIdCard) {
                                tempData.files = {
                                    ...tempData.files,
                                    idCard: convertedIdCard,
                                }
                            }
                        }

                        if (documents.backOfIdCard !== '') {
                            // @ts-ignore
                            let convertedBackOfIdCard = await toBase64(documents.backOfIdCard) as string

                            if (convertedBackOfIdCard) {
                                tempData.files = {
                                    ...tempData.files,
                                    backOfIdCard: convertedBackOfIdCard,
                                }
                            }
                        }


                        dispatch(editKycThunk(token, tempData))
                            .then((res) => {
                                if (res.data === "Updated") {
                                    setIsModalOpen(true)
                                }
                            })
                            .catch((error) => {
                                if (error.response.data === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                    return
                                }

                                if (error.response.data === "Too many phones. Max count is 5") {
                                    setErrorMessage(error.response.data)
                                    setIsErrorModalOpen(true)
                                    return
                                }

                                if (error.response.data === "Phone already exist") {
                                    setErrorMessage("This phone number already exists. Please try another phone number.")
                                    setIsErrorModalOpen(true)
                                    return
                                }

                                if (error.code === "ERR_NETWORK" && !error.response) {
                                    setErrorMessage("Maximum files size is 90 MB")
                                    setIsErrorModalOpen(true)
                                } else {
                                    setErrorMessage("Something went wrong. Please try again later.")
                                    setIsErrorModalOpen(true)
                                }
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * First name
                                        <Popover content={"Enter your First Name as in the ID document."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <MyInput id='firstName'
                                             name='firstName'
                                             onChange={handleChange}
                                             value={values.firstName}
                                             isError={errors.firstName}
                                             touched={touched.firstName}
                                    />
                                    {errors.firstName && touched.firstName &&
                                        <div className={s.error_message}>{errors.firstName}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Last name
                                        <Popover content={"Enter your Last Name as in the ID document."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <MyInput id='lastName'
                                             name='lastName'
                                             onChange={handleChange}
                                             value={values.lastName}
                                             isError={errors.lastName}
                                             touched={touched.lastName}
                                    />
                                    {errors.lastName && touched.lastName &&
                                        <div className={s.error_message}>{errors.lastName}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Country
                                    </div>
                                    <SelectWithSearch
                                        id="country"
                                        name="country"
                                        isError={errors.country}
                                        items={countries}
                                    />
                                    {errors.country && touched.country &&
                                        <div className={s.error_message}>{errors.country}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Phone number
                                    </div>
                                    <CustomDropdown
                                        items={getPhoneNumbersArr()}
                                        placeholder={"Choose your phone number"}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        isError={errors.phoneNumber}
                                        touched={touched.phoneNumber}
                                    />
                                    {errors.phoneNumber && touched.phoneNumber &&
                                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * city
                                    </div>
                                    <MyInput id='city'
                                             name='city'
                                             onChange={handleChange}
                                             value={values.city}
                                             isError={errors.city}
                                             touched={touched.city}
                                    />
                                    {errors.city && touched.city &&
                                        <div className={s.error_message}>{errors.city}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        state
                                    </div>
                                    <MyInput id='state'
                                             name='state'
                                             onChange={handleChange}
                                             value={values.state}
                                             isError={errors.state}
                                             touched={touched.state}
                                    />
                                    {errors.state && touched.state &&
                                        <div className={s.error_message}>{errors.state}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Address
                                    </div>
                                    <MyInput id='address'
                                             name='address'
                                             onChange={handleChange}
                                             value={values.address}
                                             isError={errors.address}
                                             touched={touched.address}
                                    />
                                    {errors.address && touched.address &&
                                        <div className={s.error_message}>{errors.address}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Postal code
                                    </div>
                                    <MyInput id='postalCode'
                                             name='postalCode'
                                             onChange={handleChange}
                                             value={values.postalCode}
                                             isError={errors.postalCode}
                                             touched={touched.postalCode}
                                    />
                                    {errors.postalCode && touched.postalCode &&
                                        <div className={s.error_message}>{errors.postalCode}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Identity card type
                                    </div>
                                    <CustomDropdown
                                        items={idCardsTypes}
                                        id="identityCardType"
                                        name="identityCardType"
                                        isError={errors.identityCardType}
                                        touched={touched.identityCardType}
                                        disable={values.country !== "Canada"}
                                    />
                                    {errors.identityCardType && touched.identityCardType &&
                                        <div className={s.error_message}>{errors.identityCardType}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Identity card id
                                    </div>
                                    <MyInput id='identityCardId'
                                             name='identityCardId'
                                             onChange={handleChange}
                                             value={values.identityCardId}
                                             isError={errors.identityCardId}
                                             touched={touched.identityCardId}
                                    />
                                    {errors.identityCardId && touched.identityCardId &&
                                        <div className={s.error_message}>{errors.identityCardId}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Date of Birth
                                    </div>
                                    <DatePicker suffixIcon={null} style={dateStyle}
                                                defaultValue={dayjs(new Date(), dateFormat)}
                                                value={dayjs(date.from)}
                                                onChange={(_, dateString) => {
                                                    if (!dateString) {
                                                        setDate({...date, from: new Date().toDateString()})
                                                    } else {
                                                        setDate({...date, from: dateString})
                                                    }
                                                }}
                                                format={dateFormat}/>
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Tax Number
                                    </div>
                                    <MyInput id='taxNumber'
                                             name='taxNumber'
                                             onChange={handleChange}
                                             value={values.taxNumber}
                                             isError={errors.taxNumber}
                                             touched={touched.taxNumber}
                                    />
                                    {errors.taxNumber &&
                                        <div className={s.error_message}>{errors.taxNumber}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Citizenship
                                    </div>
                                    <SelectWithSearch
                                        id="citizenship"
                                        name="citizenship"
                                        isError={errors.citizenship}
                                        items={countries}
                                        touched={touched.citizenship}
                                    />
                                    {errors.citizenship && touched.citizenship &&
                                        <div className={s.error_message}>{errors.citizenship}</div>}
                                </div>
                                <div className={s.input_block}>
                                </div>
                            </div>


                            <div className={s.row}>
                                <div className={classnames(s.input_block)}>
                                    <div className={s.input_label}>
                                        * Sources of wealth or income
                                    </div>
                                    <div role="group" aria-labelledby="checkbox-group"
                                         className={s.sources_of_wealth_wrapper}>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Inheritances")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Inheritances")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Inheritances</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Investments")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Investments")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Investments</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Business ownership interests")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Business ownership interests")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Business ownership interests</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Employment income")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Employment income")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Employment income</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Personal savings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Personal savings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Personal savings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Pension releases")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Pension releases")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Pension releases</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Share sales and dividends")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Share sales and dividends")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Share sales and dividends</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Property sales")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Property sales")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Property sales</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Gambling winnings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Gambling winnings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Gambling winnings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Inheritances and gifts")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Inheritances and gifts")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Inheritances and gifts</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Compensation from legal rulings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Compensation from legal rulings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Compensation from legal rulings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            // @ts-ignore
                                                            checked={values.isOtherSourcesOfWealth}
                                                            onChange={(e) => {
                                                                const {checked} = e.target;
                                                                if (checked) {
                                                                    props.form.setFieldValue("sourcesOfWealth", []);
                                                                }
                                                                props.form.setFieldValue(
                                                                    "isOtherSourcesOfWealth",
                                                                    checked
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Other</span>
                                        </label>
                                    </div>
                                    {errors.sourcesOfWealth && values.sourcesOfWealth.length === 0 &&
                                        <div className={s.error_message}>{errors.sourcesOfWealth}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                {
                                    // @ts-ignore
                                    values.isOtherSourcesOfWealth &&
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * Other
                                        </div>
                                        <MyInput id='otherSourcesOfWealth'
                                                 name='otherSourcesOfWealth'
                                                 onChange={handleChange}
                                                 value={values.otherSourcesOfWealth}
                                                 isError={errors.otherSourcesOfWealth}
                                                 touched={touched.otherSourcesOfWealth}
                                        />
                                    </div>
                                }
                                <div className={s.input_block}>
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={classnames(s.input_block)}>
                                    <div className={classnames(s.input_label, errors.currencies && s.doc_error)}
                                         id="checkbox-group">
                                        * Add a new account
                                    </div>
                                    <div role="group" aria-labelledby="checkbox-group" className={s.checkbox_wrapper}>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="CAD"/>
                                            <span className={s.checkbox_label}>CAD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="EUR"/>
                                            <span className={s.checkbox_label}>EUR</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="GBP"/>
                                            <span className={s.checkbox_label}>GBP</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="USD"/>
                                            <span className={s.checkbox_label}>USD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="SGD"/>
                                            <span className={s.checkbox_label}>SGD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="HKD"/>
                                            <span className={s.checkbox_label}>HKD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="JPY"/>
                                            <span className={s.checkbox_label}>JPY</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="CNH"/>
                                            <span className={s.checkbox_label}>CNH</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="AED"/>
                                            <span className={s.checkbox_label}>AED</span>
                                        </label>
                                    </div>
                                    {errors.currencies &&
                                        <div className={s.error_message}>{errors.currencies}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Politically exposed person (pep)
                                    </div>
                                    <div className={classnames(s.flex_label, s.radio_block)}>
                                        <label className={s.flex_label}>
                                            <CustomCheckbox
                                                handleChange={() => setIsPep(false)}
                                                id="No"
                                                name="No"
                                                isChecked={!isPep}
                                            />
                                            <span className={s.type_of_account}>No</span>
                                        </label>
                                        <label className={s.flex_label}>
                                            <CustomCheckbox
                                                handleChange={() => setIsPep(true)}
                                                id="Yes"
                                                name="Yes"
                                                isChecked={isPep}
                                            />
                                            <span className={s.type_of_account}>Yes</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={passportSpread} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.title, docsError && documents.passportSpread === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Passport spread with your photo
                                        </div>
                                        <div>
                                            <ul className={s.passport_spread_list}>
                                                <li>
                                        <span className={s.text_color}>
                                            A color photo contains full document in clear focus and the data can be read
                                            easily.
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                             There're no light glares impeding document readability.
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                            A photo was not edited in graphic applications (we will definitely check).
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                           Picture format: JPEG, JPG, PNG, PDF.
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                            Minimum picture resolution: 800 x 600 pixels.
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                            Maximum file size: 8Mb.
                                        </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'hiddenPassportInput'}
                                    inputId={'hiddenPassportInput'}
                                    allowedTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                                    identifier={"passportSpread"}
                                    maxFileSize={8}
                                    kycList={newKycList}
                                    documentTitle={'passport spread'}
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            passportSpread: event.currentTarget.files[0]
                                        });
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, passportSpread: ''})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={selfi} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.title, docsError && documents.selfie === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Selfie with your Passport
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload a selfie of the Account Holder holding his Passport (at the page with
                                            a
                                            photo) in
                                            one hand and a paper with written name of platform "{brandConfig.companyNameSimple}", date
                                            (dd/mm/yyyy/) and
                                            Account Holder's signature in the other hand. Photo should be good enough so
                                            it
                                            is
                                            possible to read ID data and see written on the paper text. You can use help
                                            of
                                            another
                                            person to make this photo or use a delayed photo function on your phone.
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'hiddenSelfieInput'}
                                    inputId={'hiddenSelfieInput'}
                                    allowedTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                                    identifier={"selfie"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'selfie with passport'}
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setDocuments({...documents, selfie: event.currentTarget.files[0]})
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, selfie: ''})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={proof} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.title, docsError && documents.proofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Personal Proof of Address
                                        </div>
                                        <div>
                                            <ul className={s.passport_spread_list}>
                                                <li>
                                        <span className={s.text_color}>
                                            Bank statement with the date of issue and the name of the person (the document
                                            must be not older than 3 months);
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                             Utility bill for gas, electricity, water, internet, etc. linked to the property
                                            (the document must not be older than 3 months).
                                        </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'hiddenProofOfAddressInput'}
                                    inputId={'hiddenProofOfAddressInput'}
                                    allowedTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                                    identifier={"proofOfAddress"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'proof of address'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            proofOfAddress: event.currentTarget.files[0]
                                        })
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, proofOfAddress: ''})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={idCard} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={s.title}>
                                            ID Card/Residence Permit Card
                                        </div>
                                        <div className={s.add_block_text}>
                                            If you have National ID Card or Residence Permit Card, please upload it.
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'hiddenIdCardInput'}
                                    inputId={'hiddenIdCardInput'}
                                    allowedTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                                    identifier={"idCard"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'ID Card/Residence Permit Card'}
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setDocuments({...documents, idCard: event.currentTarget.files[0]})
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, idCard: ''})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={idCard} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={s.title}>
                                            Back of ID Card/Residence Permit Card
                                        </div>
                                        <div className={s.add_block_text}>
                                            If you uploaded ID Card/Driving license, please upload the back side of it.
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'hiddenBackOfIdCardInput'}
                                    inputId={'hiddenBackOfIdCardInput'}
                                    allowedTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                                    identifier={"backOfIdCard"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'back of ID Card/Residence Permit Card'}
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setDocuments({...documents, backOfIdCard: event.currentTarget.files[0]})
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, backOfIdCard: ''})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.btn_block}>
                                <div className={s.button_back_wrapper}>
                                    <TransparentButton
                                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                        title={"Back"}
                                        onClick={() => props.setCurrent(0)}/>
                                </div>
                                <div className={s.button_apply_wrapper}>
                                    <MyBtn title={'Apply'} type={'submit'}/>
                                </div>
                            </div>

                        </form>
                    )}
                </Formik>


            </div>
        </motion.div>
    );
};

export default EditKycPersonal;
