import React from "react";
import s from "./UnderMaintenanceHeaderBlock.module.css";
import MyBtn from "../ui/MyBtn/MyBtn";
import { useNavigate } from "react-router-dom";
import headerImage from "../../assets/newImg/publicPages/services/UnderMaintenance/main.svg";
import backIcon from "../../assets/newImg/publicPages/services/UnderMaintenance/back-white.svg";

const UnderMaintenanceHeaderBlock = () => {
    const navigate = useNavigate();

    return (
        <div className={s.contentContainer}>
            <img src={headerImage} alt="under maintenance" className={s.imageBlock} />
            <div className={s.title}>
                We Are Currently Working On this Page To deliver A Better User
                Experience
            </div>
            <div className={s.buttonBlock}>
                <MyBtn title={"Go Back"} icon={backIcon} onClick={() => navigate("/")} />
            </div>
        </div>
    );
};

export default UnderMaintenanceHeaderBlock;
