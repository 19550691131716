import React from "react";
import s from "./DedicatedIbans.module.css";
import { motion } from "framer-motion";
import UnderMaintenanceHeaderBlock from "../../../../components/UnderMaintenanceHeaderBlock/UnderMaintenanceHeaderBlock";

const DedicatedIbans = () => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            <div className={s.container}>
                <div className={s.wrapper}>
                    <UnderMaintenanceHeaderBlock />
                </div>
            </div>
        </motion.div>
    );
};

export default DedicatedIbans;
